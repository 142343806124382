.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.placeholder-text {
  position: absolute;
  top: 45%;
  width: 100%;
  animation: pulse infinite 1s linear 
}

.telemetry {
  bottom: 3%;
  position: fixed;
  text-align: left;
  font-size: 13px;
  color: burlywood;
  opacity: .9;
}

.telemetry.right {
  right: 3%;
}

.telemetry.left {
  left: 3%;
}

.metric {
  display: inherit;
  margin-top: 2px
}

.green {
  color: green;
  float: right;
  margin-left: 5px;
}

.orange {
  color: orange;
  float: right;
  margin-left: 5px;
}

@keyframes pulse {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}